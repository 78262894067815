import useTranslation from 'next-translate/useTranslation';

import { Translate } from '@core/constant';
import { EFilenameBlur, getStaticPathImage, getStaticPathImageBlurData } from '@core/media';
import { EColor, ELabel } from '@core/type';

import { Image } from '../image';
import { ProductLabel } from './ProductLabel';
import { LabelSmallProps } from './interface-status';

export const LabelNew = ({ labelSize }: LabelSmallProps) => {
  const { t: tProduct } = useTranslation(Translate.common.PRODUCT);

  return (
    <ProductLabel
      type={ELabel.NEW}
      display="flex"
      gap="3px"
      alignItems="center"
      labelColor={EColor.SECONDARY}
      size={labelSize}
    >
      <Image
        alt={`Rocket icon`}
        width={14}
        height={14}
        style={{ objectFit: 'cover' }}
        src={getStaticPathImage('product/rocket.png')}
        blurDataURL={getStaticPathImageBlurData(EFilenameBlur['product/rocket.png'])}
      />{' '}
      {tProduct('labels.new').toUpperCase()}
    </ProductLabel>
  );
};
