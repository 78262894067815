import { Modal, Typography } from '@core/component';
import { EColor, ETypographyVariant } from '@core/type';

import { HeaderModalProps } from '../interface-modal-alert';

export const HeaderModal = ({ title, subTitle }: HeaderModalProps) => (
  <Modal.Header>
    <Typography variant={ETypographyVariant.H5}>{title}</Typography>
    <Typography
      variant={ETypographyVariant.H5}
      color={{ commonType: EColor.BLACK, intensity: EColor.R900 }}
    >
      {subTitle}
    </Typography>
  </Modal.Header>
);
