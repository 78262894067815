import useTranslation from 'next-translate/useTranslation';
import { FC, memo } from 'react';
import styled, { css, CSSObject } from 'styled-components';

import { labelColors, Translate } from '@core/constant';
import {
  EColor,
  EFontWeight,
  EIcon,
  ELabel,
  ESize,
  EStatus,
  LabelColorType,
  LabelStateType,
  WithThemeProps,
} from '@core/type';
import { getLabelColorProps } from '@core/util';

import { Icon } from '../icon';
import { Box } from '../layout/Box';
import { BoxProps } from '../layout/interface-layout';
import { LabelProps } from './interface-label';

const LabelContainer = ({
  type,
  color: colorProp = EColor.ACCENT,
  withInfoIcon,
  children,
  metal,
  margin,
  ...restProps
}: LabelProps) => {
  const { t } = useTranslation(Translate.common.COMMON);
  const { t: tProduct } = useTranslation(Translate.common.PRODUCT);

  const labelText: { [key in LabelStateType]: string } & { [key in EStatus]: string } = {
    [ELabel.PREORDER]: tProduct('labels.preorder'),
    [ELabel.STORAGE_ONLY]: tProduct('labels.storageOnly'),
    [EStatus.PAUSED]: t('statuses.paused'),
    [EStatus.ACTIVATED]: t('statuses.activated'),
    [EStatus.PAID]: t('statuses.paid'),
    [EStatus.PENDING]: t('statuses.pending'),
    [EStatus.IN_PROGRESS]: t('statuses.inProgress'),
    [EStatus.COMPLETED]: t('statuses.completed'),
    [EStatus.CONFIRMED]: t('statuses.confirmed'),
    [EStatus.ACCEPTED]: t('statuses.accepted'),
    [EStatus.CANCELED]: t('statuses.canceled'),
    [EStatus.FREE]: t('statuses.free'),
    [EStatus.FREE_PERIOD]: t('statuses.freePeriod'),
    [EStatus.RETURNED]: t('statuses.returned'),
    [EStatus.REJECTED]: t('statuses.canceled'),
    [EStatus.IN_PREPARATION]: t('statuses.inPreparation'),
    [EStatus.SHIPPED]: t('statuses.shipped'),
  };

  const color = (labelColors[type] as LabelColorType) || colorProp;

  return (
    <StyledLabel
      fontWeight={EFontWeight.MEDIUM}
      color={color}
      margin={margin}
      display={'inline-flex'}
      alignItems={'center'}
      {...restProps}
    >
      {labelText[type] || children}
      {withInfoIcon && (
        <Icon
          marginLeft={'4px'}
          type={EIcon.INFO}
          color={color}
          size={ESize.XXS}
          flexShrink={'0'}
        />
      )}
    </StyledLabel>
  );
};

export const Label = memo(LabelContainer);

export const StyledLabel: FC<BoxProps & LabelProps> = styled(Box)((
  props: BoxProps & LabelProps & WithThemeProps,
) => {
  const {
    theme: {
      palette,
      structure: {
        label: { base: baseStyles, sizes: labelSizeStyles },
      },
    },
    onClick,
    animation,
    color,
    size = ESize.SM,
    ...restProps
  } = props;
  const labelColorStyles = getLabelColorProps({ color: color as LabelColorType, palette });

  return css`
    ${baseStyles as CSSObject};
    ${labelSizeStyles[size] as CSSObject};
    ${labelColorStyles as CSSObject};
    ${restProps as CSSObject}
  `;
});
