import { useEventAddToCart } from '@google-tag-manager';
import { useEventMixpanel } from '@mixpanel';
import Trans from 'next-translate/Trans';
import useTranslation from 'next-translate/useTranslation';
import { memo, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';

import {
  Box,
  Button,
  ButtonQuantityControl,
  FormControl,
  Line,
  Modal,
  SelectTextControl,
  Typography,
} from '@core/component';
import { SelectBaseOptionProps } from '@core/component/interface';
import { Translate } from '@core/constant';
import { useContextCart, useContextCurrency, useContextMediaQuery } from '@core/context';
import { useLanguage, useValidator } from '@core/hook';
import {
  EActionAttribute,
  EButtonVariant,
  EColor,
  EFontWeight,
  EFrequency,
  ESize,
  ETypographyVariant,
  ModalAutoSavingsForm,
  modalAutoSavingsValidator,
} from '@core/type';
import { CartProductEntity, EDeliveryType } from '@core/type/api';
import { formatCurrency } from '@core/util';

import { LinkProductLine } from '../../card/LinkProductLine';
import { ModalAutoSavingsProps } from '../interface-modal';
import { AutoSavingsNextPurchaseInfoBlock } from './AutoSavingsNextPurchaseInfoBlock';
import { ModalAutoSavingsHeader } from './ModalAutoSavingsHeader';

const ModalAutoSavingsContainer = ({
  onCloseModal,
  quantity = 1,
  isQuantityEditable = true,
  product,
  ...restProps
}: ModalAutoSavingsProps) => {
  const { isPhone } = useContextMediaQuery();
  const { language } = useLanguage();

  const { t } = useTranslation(Translate.common.MODAL);
  const { t: tButton } = useTranslation(Translate.common.BUTTON);
  const { t: tForm } = useTranslation(Translate.common.FORM);

  const {
    currentStock,
    totalPrice,
    repeatBuyFrequency,
    currencyIso,
    preorder,
    totalPriceWithDeal,
  } = product;

  const { handleSubmit, control, trigger, reset, setError, watch, setValue } =
    useForm<ModalAutoSavingsForm>({
      defaultValues: {
        frequency: repeatBuyFrequency || null,
        quantity: quantity,
      },
      resolver: useValidator(modalAutoSavingsValidator, tForm),
      mode: 'onChange',
    });

  const productQty = watch('quantity');

  useEffect(() => {
    if (repeatBuyFrequency !== watch('frequency')) {
      setValue('frequency', repeatBuyFrequency);
    }

    if (quantity !== productQty) {
      setValue('quantity', quantity);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [repeatBuyFrequency, quantity]);

  const { setAutoSavingsFrequency, addToCart } = useContextCart();
  const { pushEventAddToCart } = useEventAddToCart();
  const { trackProductAddedToCartEvent } = useEventMixpanel();
  const { currency } = useContextCurrency();

  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);

  const options: SelectBaseOptionProps[] = useMemo(
    () => [
      { label: t('autoSavings.selectWeekly'), value: EFrequency.WEEKLY },
      { label: t('autoSavings.selectMonthly'), value: EFrequency.MONTHLY },
      { label: t('autoSavings.selectQuarterly'), value: EFrequency.QUARTERLY },
      { label: t('autoSavings.selectTwiceYearly'), value: EFrequency.SEMIANNUALLY },
      { label: t('autoSavings.selectAnnually'), value: EFrequency.ANNUALLY },
      { label: t('autoSavings.selectOnce'), value: EFrequency.NEVER },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [language],
  );

  const handleFormCb = ({ frequency, quantity }: ModalAutoSavingsForm) => {
    if (isQuantityEditable) {
      reset();
      onCloseModal();

      const productData: CartProductEntity = {
        ...product,
        quantity,
        repeatBuyFrequency: frequency,
      };

      const shippingMethod = {
        shippingType: EDeliveryType.STORAGE,
      };

      return void addToCart(productData, undefined, shippingMethod).then(({ products }) => {
        pushEventAddToCart([productData], products);
        trackProductAddedToCartEvent({ ...productData, price: productData.totalPrice });
      });
    } else {
      return setAutoSavingsFrequency(product.id, frequency).then(() => {
        reset();
        onCloseModal();
      });
    }
  };

  useEffect(() => {
    return () => reset();
  }, [reset]);

  const totalProductPrice = isQuantityEditable ? totalPrice * productQty : totalPrice;
  const dealPrice = product.deal ? totalPriceWithDeal : totalPrice;
  const totalDealPrice = isQuantityEditable ? dealPrice * productQty : dealPrice;

  return (
    <Modal size={ESize.LG} onCloseModal={onCloseModal} {...restProps}>
      <ModalAutoSavingsHeader />
      <FormControl
        t={tForm}
        trigger={trigger}
        reset={reset}
        handleSubmit={handleSubmit}
        setError={setError}
        onInvalid={() => setIsSubmitted(true)}
        fn={handleFormCb}
      >
        <Modal.Body>
          <Line marginBottom={'16px'} />
          <Box
            display={'flex'}
            gap={isPhone ? '16px' : '32px'}
            alignItems={'center'}
            justifyContent={'space-between'}
            flexWrap={isPhone ? 'wrap' : 'nowrap'}
          >
            <LinkProductLine {...product} withInfoIcon={false} />

            <Box
              width={isPhone ? '100%' : 'auto'}
              display={'flex'}
              alignItems={'center'}
              justifyContent={'space-between'}
              flexShrink={0}
              gap={'32px'}
            >
              <Box flexShrink={0}>
                {isQuantityEditable ? (
                  <ButtonQuantityControl
                    name={'quantity'}
                    size={ESize.SM}
                    control={control}
                    min={1}
                    max={preorder?.isPreorder ? preorder.preorderStock : currentStock}
                  />
                ) : (
                  <Typography width={'48px'}>{quantity}</Typography>
                )}
              </Box>

              {product.deal ? (
                <Box display="flex" flexDirection="column" whiteSpace={'nowrap'}>
                  <Typography color={EColor.DANGER} variant={ETypographyVariant.H6}>
                    {formatCurrency(language, currency, totalDealPrice)}
                  </Typography>
                  <Typography
                    color={EColor.SECONDARY}
                    textDecoration="line-through"
                    variant={ETypographyVariant.H6}
                  >
                    {formatCurrency(language, currency, totalProductPrice)}
                  </Typography>
                </Box>
              ) : (
                <Typography
                  whiteSpace={'nowrap'}
                  minWidth={'90px'}
                  flexShrink={'0'}
                  textAlign={'right'}
                >
                  {formatCurrency(language, currencyIso || currency, totalProductPrice)}
                </Typography>
              )}
            </Box>
          </Box>

          <Line marginTop={'16px'} marginBottom={'32px'} />

          {product.deal && (
            <Typography
              variant={ETypographyVariant.BODY2}
              marginBottom={'32px'}
              textAlign={isPhone ? 'center' : 'left'}
            >
              <Trans
                i18nKey={`${Translate.common.MODAL}:autoSavings.note`}
                components={{
                  boldText: (
                    <Typography
                      as={EActionAttribute.SPAN}
                      variant={ETypographyVariant.BODY2}
                      fontWeight={EFontWeight.BOLD}
                    />
                  ),
                }}
              />
            </Typography>
          )}

          <Typography marginBottom={'16px'} variant={ETypographyVariant.H6}>
            {t('autoSavings.selectTitle')}
          </Typography>

          <Box marginBottom={'24px'}>
            <SelectTextControl
              name={'frequency'}
              control={control}
              placeholder={t('autoSavings.selectPlaceholder')}
              options={options}
              isSubmitted={isSubmitted}
            />
          </Box>

          <AutoSavingsNextPurchaseInfoBlock frequency={watch('frequency')} language={language} />
        </Modal.Body>
        <Modal.Footer>
          <Box display={'flex'} gap={'24px'} justifyContent={'center'}>
            <Button type={'button'} onClick={onCloseModal} variant={EButtonVariant.OUTLINED}>
              {tButton('cancel.title')}
            </Button>
            {isQuantityEditable ? (
              <Button type={'submit'}>{tButton('addToCart.title')}</Button>
            ) : (
              <Button type={'submit'}>{tButton('save.title')}</Button>
            )}
          </Box>
        </Modal.Footer>
      </FormControl>
    </Modal>
  );
};

export const ModalAutoSavings = memo(ModalAutoSavingsContainer);
