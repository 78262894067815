import useTranslation from 'next-translate/useTranslation';
import { memo, useEffect } from 'react';
import { useForm } from 'react-hook-form';

import {
  Box,
  FormControl,
  InputCheckboxControl,
  Line,
  Modal,
  ModalProps,
  Typography,
} from '@core/component';
import { Translate } from '@core/constant';
import { useContextUser } from '@core/context';
import { useStockAlert, useValidator } from '@core/hook';
import {
  EColor,
  EContactMethod,
  ECountry,
  ETypographyVariant,
  StockAlertForm,
  stockAlertSchema,
} from '@core/type';
import { StockAlertUpdateProps } from '@core/type/api';
import { getInternationalPrefix } from '@core/util';

import {
  ButtonsModalUpdateAlert,
  LabelStockAlertPhoneNumber,
  LinePhoneNumber,
  LineStockAlertField,
  ModalSuccessStockAlertUpdate,
} from './components';
import { ModalUpdateStockAlertProps } from './interface-modal-alert';

const ModalUpdateStockAlertContainer = ({
  stockAlert,
  isOpen,
  onCloseModal,
}: Omit<ModalProps, 'children'> & ModalUpdateStockAlertProps) => {
  const { updateStockAlert, removeFromStockAlerts } = useStockAlert();
  const { phone } = useContextUser();

  const { t: tForm } = useTranslation(Translate.common.FORM);
  const { t: tProduct } = useTranslation(Translate.common.PRODUCT);

  const {
    handleSubmit,
    control,
    trigger,
    reset,
    setError,
    watch,
    getValues,
    formState: { errors, isSubmitSuccessful },
  } = useForm<StockAlertForm>({
    defaultValues: {
      isSendSms: stockAlert.notification === EContactMethod.SMS,
      quantity: stockAlert.quantity,
      phoneNumber: phone?.number ?? '',
      phoneCode: phone?.countryIso ?? ECountry.SWITZERLAND,
    },
    resolver: useValidator(stockAlertSchema, tForm),
    mode: 'onChange',
  });

  const deleteAlert = () => {
    removeFromStockAlerts(stockAlert.id);
    onCloseModal();
  };

  const isSendSms = watch('isSendSms', false);

  const handleFormCb = (data: StockAlertForm) => {
    const submitData: StockAlertUpdateProps = {
      quantity: data.quantity,
      isSendSms: data.isSendSms,
      id: stockAlert.id,
      phone: data.isSendSms
        ? {
            code: phone?.code ?? getInternationalPrefix(data.phoneCode),
            countryIso: phone?.countryIso ?? data.phoneCode,
            number: phone?.number ?? data.phoneNumber,
          }
        : undefined,
    };

    return updateStockAlert(submitData);
  };

  useEffect(() => {
    if (!isOpen) {
      reset();
    }
  }, [isOpen, reset]);

  return (
    <>
      <Modal isOpen={isOpen && !isSubmitSuccessful} onCloseModal={onCloseModal}>
        <Modal.Header>
          <Typography variant={ETypographyVariant.H5}>
            {tProduct('stockAlerts.newAlertModal.stockAlert')}
          </Typography>
          <Typography
            variant={ETypographyVariant.H5}
            color={{ commonType: EColor.BLACK, intensity: EColor.R900 }}
          >
            {stockAlert.product.name}
          </Typography>
        </Modal.Header>
        <Modal.Body>
          <Box display="flex" marginBottom="32px">
            <Typography variant={ETypographyVariant.BODY3}>
              {tProduct('stockAlerts.editAlertModal.alreadySet')}
            </Typography>
          </Box>
          <Line marginBottom="32px" />

          <FormControl
            t={tForm}
            trigger={trigger}
            reset={reset}
            handleSubmit={handleSubmit}
            setError={setError}
            fn={handleFormCb}
          >
            <Typography marginBottom={'16px'}>
              {tProduct('stockAlerts.newAlertModal.yourQuantity')}
            </Typography>

            <LineStockAlertField control={control} product={stockAlert.product} errors={errors} />

            <Box marginBottom="16px">
              <InputCheckboxControl
                control={control}
                name="isSendSms"
                label={<LabelStockAlertPhoneNumber />}
              />
            </Box>

            <LinePhoneNumber
              control={control}
              errors={errors}
              phoneCode={getValues('phoneCode')}
              isSendSms={isSendSms}
            />

            <Line marginTop="16px" marginBottom="16px" />
            <ButtonsModalUpdateAlert onDelete={deleteAlert} onCancel={onCloseModal} />
          </FormControl>
        </Modal.Body>
      </Modal>
      <ModalSuccessStockAlertUpdate
        name={stockAlert.product.name}
        isOpen={isOpen && isSubmitSuccessful}
        onCloseModal={onCloseModal}
      />
    </>
  );
};

export const ModalUpdateStockAlert = memo(ModalUpdateStockAlertContainer);
