import { FC } from 'react';
import styled from 'styled-components';

import { Typography } from './Typography';
import { TextWithLineLimitProps } from './interface-typography';

/*
  Put lines count to limit the text
*/

const DEFAULT_LINES_COUNT = 2;

export const TextWithLineLimit = ({
  children,
  hasLimit = true,
  ...restProps
}: TextWithLineLimitProps) => (
  <TextWithLineLimitStyled hasLimit={hasLimit} {...restProps}>
    {children}
  </TextWithLineLimitStyled>
);

export const TextWithLineLimitStyled: FC<TextWithLineLimitProps> = styled(Typography)(
  ({ lines = DEFAULT_LINES_COUNT, height, hasLimit }: TextWithLineLimitProps) => ({
    height,
    overflow: 'hidden',
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': hasLimit ? `${lines}` : 'none',
  }),
);
