import useTranslation from 'next-translate/useTranslation';
import { MouseEventHandler, useState } from 'react';

import { Box, Link } from '@core/component';
import { Translate } from '@core/constant';
import { useContextMediaQuery } from '@core/context';
import {
  EActionAttribute,
  EColor,
  EFontWeight,
  EIcon,
  ESize,
  ETypographyVariant,
} from '@core/type';

import { ModalAutoSavings } from '../modal/modal-auto-savings/ModalAutoSavings';
import { LinkAutoSavingsBadgeProps } from './interface-product-card';

export const LinkAutoSavingsBadge = ({ product, quantity }: LinkAutoSavingsBadgeProps) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const { isMobile } = useContextMediaQuery();
  const { t } = useTranslation(Translate.common.PRODUCT);

  const handleOpenModal: MouseEventHandler<HTMLButtonElement | HTMLAnchorElement> = (e) => {
    e.preventDefault();

    setIsModalOpen(true);
  };

  return (
    <Box
      textAlign="right"
      onClick={isMobile && !isModalOpen ? handleOpenModal : null}
      lineHeight={'48px'}
    >
      <Link
        startIcon={EIcon.CART_REPEAT_ROUNDED}
        as={EActionAttribute.SPAN}
        iconSize={ESize.MD}
        iconColor={EColor.ACCENT}
        variant={ETypographyVariant.H6}
        fontWeight={EFontWeight.BOLD}
        isChangingColorHover={true}
        isIconFill={true}
        iconTop="7px"
        iconMargin={5}
        fontSize={isMobile ? '12px' : '16px'}
        color={EColor.ACCENT}
        onClick={isMobile ? null : handleOpenModal}
      >
        {t('buttons.autoSavings')}
      </Link>
      <ModalAutoSavings
        isOpen={isModalOpen}
        product={product}
        quantity={quantity}
        onCloseModal={() => setIsModalOpen(false)}
      />
    </Box>
  );
};
