import Trans from 'next-translate/Trans';
import useTranslation from 'next-translate/useTranslation';
import { memo } from 'react';

import { Box, Icon, Modal, Typography } from '@core/component';
import { Translate } from '@core/constant';
import { EIcon, ESize, ETypographyVariant } from '@core/type';

const ModalHeaderContainer = () => {
  const { t } = useTranslation(Translate.common.MODAL);

  return (
    <Modal.Header>
      <Box
        display="flex"
        gap="16px"
        alignItems="center"
        marginBottom="16px"
        justifyContent="center"
      >
        <Icon type={EIcon.CART_REPEAT} size={ESize.MD} />
        <Typography variant={ETypographyVariant.H4}>{t('autoSavings.title')}</Typography>
      </Box>
      <Typography textAlign="center">
        <Trans
          i18nKey={`${Translate.common.MODAL}:autoSavings.description`}
          components={{ br: <br /> }}
        />
      </Typography>
    </Modal.Header>
  );
};

export const ModalAutoSavingsHeader = memo(ModalHeaderContainer);
