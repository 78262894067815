import useTranslation from 'next-translate/useTranslation';
import { memo } from 'react';

import { Box, Button } from '@core/component';
import { Translate } from '@core/constant';
import { EButtonVariant } from '@core/type';

import { ButtonsModalUpdateAlertProps } from '../interface-modal-alert';

const ButtonsModalUpdateAlertContainer = ({ onDelete, onCancel }: ButtonsModalUpdateAlertProps) => {
  const { t: tButton } = useTranslation(Translate.common.BUTTON);
  const { t: tProduct } = useTranslation(Translate.common.PRODUCT);

  return (
    <Box display="flex" justifyContent="space-between">
      <Box>
        <Button type="submit">{tButton('update.title')}</Button>
        <Button
          marginLeft="16px"
          variant={EButtonVariant.OUTLINED}
          onClick={(e) => {
            e.preventDefault();
            onDelete();
          }}
        >
          {tButton('remove.title')}
        </Button>
      </Box>

      <Button type={'button'} variant={EButtonVariant.OUTLINED} onClick={onCancel}>
        {tProduct('stockAlerts.newAlertModal.btnCancel')}
      </Button>
    </Box>
  );
};

export const ButtonsModalUpdateAlert = memo(ButtonsModalUpdateAlertContainer);
