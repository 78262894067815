import Trans from 'next-translate/Trans';

import { Translate } from '@core/constant';
import { EColor } from '@core/type';

import { ProductLabel } from './ProductLabel';
import { LabelDealProps, LabelSmallProps } from './interface-status';

export const LabelDeal = ({ labelSize, percentage }: LabelSmallProps & LabelDealProps) => (
  <ProductLabel
    display="flex"
    gap="3px"
    alignItems="center"
    labelColor={EColor.DANGER}
    size={labelSize}
  >
    <Trans i18nKey={`${Translate.common.PRODUCT}:labels.percentage`} values={{ percentage }} />
  </ProductLabel>
);
