import Trans from 'next-translate/Trans';
import useTranslation from 'next-translate/useTranslation';
import styled from 'styled-components';

import { ButtonClose, Modal, ModalBackgroundProps, Typography } from '@core/component';
import { Translate } from '@core/constant';
import { EActionAttribute, ESize, ETypographyVariant } from '@core/type';

const List = styled.ol`
  list-style-position: inside;
`;

export const ModalWhatIsPreorder = ({ isOpen, onCloseModal }: ModalBackgroundProps) => {
  const { t } = useTranslation(Translate.common.PRODUCT);

  return (
    <Modal isOpen={isOpen} onCloseModal={onCloseModal} size={ESize.LG}>
      <Modal.Header>
        <Typography variant={ETypographyVariant.H2} textAlign="center">
          {t('modals.preorderInfo.whatIsPresale')}
        </Typography>
      </Modal.Header>

      <Typography marginBottom="24px">
        <Trans
          i18nKey={`${Translate.common.PRODUCT}:modals.preorderInfo.ourPresaleOption`}
          components={{
            boldText: <Typography as={EActionAttribute.SPAN} variant={ETypographyVariant.H6} />,
          }}
        ></Trans>
      </Typography>

      <Typography marginBottom="24px">{t('modals.preorderInfo.productsWithDelay')}</Typography>

      <Typography marginBottom="8px" variant={ETypographyVariant.H6}>
        {t('modals.preorderInfo.howPresaleWork')}
      </Typography>

      <List>
        <Typography as={EActionAttribute.LI} marginBottom="8px">
          {t('modals.preorderInfo.1OrderProduct')}
        </Typography>
        <Typography as={EActionAttribute.LI} marginBottom="8px">
          {t('modals.preorderInfo.2WeGiveEstimatedDate')}
        </Typography>
        <Typography as={EActionAttribute.LI} marginBottom="8px">
          {t('modals.preorderInfo.3ProductReady')}
        </Typography>
      </List>

      <Modal.Footer display="flex" justifyContent="center">
        <ButtonClose onClick={onCloseModal} />
      </Modal.Footer>
    </Modal>
  );
};
